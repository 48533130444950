<template>
  <div>
    <Top v-if="!isFinish"/>
    <navBar title="会员权益" router="/package" v-if="!title"/>
    <div class="navbarbox" v-if="!title"></div>
    <div class="main">
      <div class="member">
        <div class="members" v-for="(item,index) in arr" :class="massg===index?'':'trans'" :key="index" @click="trigger(index,item.priceid)">
<!--          <div class="members" v-for="(item,index)in list" :key>-->
          <img :src="item.pdcurls[0].url">
          <span>{{item.title}}</span>
        </div>
      </div>
<!-- <div style="padding: 2vw 0 2vw 4.1vw;border-radius: 2.9vw;background-color: #fff;">
  <div style="margin: 7vw 0;padding: 2vw 0;">
    <p style="margin-bottom: 4.4vw ;line-height: 4.5vw;"><span style="font-size: 16px;font-weight: 700;">特权内容</span></p>
    <p style="line-height: 4.5vw;">二甲主治以上医生三分钟内接诊三分钟内接诊三分钟内接诊三 分钟内接诊</p>
  </div>
  <div style="margin: 7vw 0;padding: 2vw 0;">
    <p style="margin-bottom: 4.4vw ;line-height: 4.5vw;"><span style="font-size: 16px;font-weight: 700;">特权内容</span></p>
    <p style="line-height: 4.5vw;">二甲主治以上医生三分钟内接诊三分钟内接诊三分钟内接诊三 分钟内接诊</p>
  </div>
</div> -->
      <div class="agreements" ref="agr">
<!--        <div class="agreement" style="padding: 2vw 0 2vw 4.1vw;border-radius: 2.9vw;background-color: #fff;">-->
<!--          <div class="agree" style="margin: 7vw 0;padding: 2vw 0;">-->
<!--            <p class="agrees" style="margin-bottom: 4.4vw ;line-height: 4.5vw;"><van-icon name="fire-o" color="#ee0a24" /><span style="padding-left: 3vw;font-size: 16px;font-weight: 700;">特权内容</span></p>-->
<!--            <p style="line-height: 4.5vw;">二甲主治以上医生三分钟内接诊三分钟内接诊三分钟内接诊三 分钟内接诊</p>-->
<!--          </div>-->
<!--        </div>-->
      </div>
      <div v-html="html"></div>
      <van-button round type="info" color="#06C170" @click="going">使用权益</van-button>
    </div>
  </div>
</template>

<script>
import navBar from '../components/navBar'
import Top from '../components/top'
import { getSrvInfoSnps, reduceSrv } from '../axios/consultation/home'
export default {
  name: 'interests',
  components: {
    navBar,
    Top
  },
  data () {
    return {
      isFinish: false,
      arr: 3,
      list: [],
      massg: 0,
      title: window.navigator.userAgent.toLowerCase().match(/MicroMessenger/i),
      html: null
    }
  },
  created () {
    if (sessionStorage.getItem('isFinish')) {
      this.isFinish = sessionStorage.getItem('isFinish')
    }
    this.massg = Number(sessionStorage.getItem('massg'))
    this.arr = JSON.parse(sessionStorage.getItem('arr'))
    console.log(this.arr[this.massg].priceid)
    this.getSrvInfoSnps(this.arr[this.massg].priceid)
  },
  methods: {
    // reduceSrv(){
    //   let data= {
    //     srvid:
    //   }
    // },
    trigger (val, key) {
      // console.log(val, key)
      this.massg = val
      this.getSrvInfoSnps(key)
    },
    // triggers (val) {
    //   console.log(val)
    // },
    getSrvInfoSnps (key) {
      getSrvInfoSnps(key).then((res) => {
        this.$refs.agr.innerHTML = ''
        // console.log(res.data[0].content)
        this.$refs.agr.innerHTML += res.data[0].content
        // this.html=res.data[0].content
      })
    },
    going () {
      console.log(this.massg)
      console.log(this.arr[this.massg].srvid)
      const data = {
        srvid: this.arr[this.massg].srvid
      }
      reduceSrv(data).then(res => {
        console.log(res.data)
        const memberId = sessionStorage.getItem('memberId')
        console.log(memberId)
        const token = sessionStorage.getItem('token')
        // const isFinish = sessionStorage.getItem('isFinish')
        const srvId = res.data.srvId
        if (res.data.srvType === 'DIRECTINQUIRY') {
          // window.location.href=``
          window.location.href = `http://mc.xlkyy.com.cn/rain/#/?memberId=${memberId}&srvId=${srvId}&isFinish=true&token=${token}`
          // console.log(window)
        } else if (res.data.srvType === 'RAPIDINQUIRY') {
          window.location.href = `http://mc.xlkyy.com.cn/rain/#/fast?memberId=${memberId}&srvId=${srvId}&isFinish=true&token=${token}`
          // console.log(`http://mc.xlkyy.com.cn/rain/#/fast?memberId=${memberId}&srvId=${srvId}&token=${token}`)
        }
      })
      // this.$toast('请您按照权益规则正确使用健康管理权益')
    }
  }
}
</script>

<style scoped lang="less">
.navbarbox {
  height: 7vh;
  width: 100%;
}
.trans{
  opacity: 0.5;
}
.main{
  font-size: 14px;
  margin: 0 2.6vw;
  position: relative;
  //.member::-webkit-scrollbar{
  //  display: none;
  //}
  .member::-webkit-scrollbar {
    display: none;
  }
  .member{
    //width: 100%;
    height: 31.7vw;
    background-image: linear-gradient(to right, #DFBD9B,#F4E2CC);
    border-radius: 2.9vw 2.9vw 0px 0px;
    display: flex;
    justify-content: left;
    overflow-x: scroll;
    flex-shrink: 0;
    .members{
      text-align: center;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 2.6vw;
      img{
        width: 11.7vw;
        height: 11.7vw;
        border-radius: 50%;
      }
      span{
           overflow: hidden;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        white-space:nowrap;
        width:20vw;
        display:block;
      }
    }
  }
  .agreements{
    margin-bottom: 6.6vw;
    position: relative;
    bottom: 2vw;
    .agreement{
      padding: 2vw 0 2vw 4.1vw;
      border-radius: 2.9vw;
      background-color: #fff;
      .agree{
        margin: 7vw 0;
        padding: 2vw 0;
        .agrees{
          margin-bottom: 4.4vw
        }
        p{
          line-height: 4.5vw;
        }
        span{
          padding-left: 3vw;
          font-size: 16px;
          font-weight: 700;
        }
      }
    }
  }
  .van-button{
    width: 100%;
    height: 11.7vw;
    margin-bottom: 10vw;
  }
}
</style>
