import axios from 'axios'
import { Toast } from 'vant'
if (process.env.NODE_ENV === 'production') {
  // axios.defaults.baseURL = 'http://gt.xlkyy.com.cn:18000'// 正式服务
  // axios.defaults.baseURL = 'http://g.xlkyy.com.cn:8000'// 正式服务
  axios.defaults.baseURL = 'http://g.xlkyy.com.cn'// 正式服务
  // axios.defaults.baseURL = 'http://192.168.3.214:18000'
  // axios.defaults.baseURL = 'http://192.168.3.233:18000'
} else {
// 开发环境
//   axios.defaults.baseURL = 'http://gt.xlkyy.com.cn:18000'
  // axios.defaults.baseURL = 'http://g.xlkyy.com.cn:8000'
  axios.defaults.baseURL = 'https://g.xlkyy.com.cn'
  // axios.defaults.baseURL = 'http://192.168.3.214:18000'
  // axios.defaults.baseURL = 'http://192.168.3.233:18000/'
}
// axios.defaults.headers.common.Accept = 'application/json, */*'
axios.defaults.headers['Content-Type'] = 'application/json'
// axios.defaults.headers['async'] = 'false'
// let token = ''
// if (process.env.NODE_ENV === 'production') {
//   if (!sessionStorage.getItem('token')) {
//     if (window.location.href.split('?')[1]) {
//       const query = window.location.href.split('?')[1]
//       const vars = query.split('&')
//       for (let i = 0; i < vars.length; i++) {
//         const pair = vars[i].split('=')
//         if (pair[0] === 'token') {
//           console.log(pair, 999)
//           token = pair[1]
//           sessionStorage.setItem('token', pair[1])
//         }
//       }
//     }
//   } else {
//     token = sessionStorage.getItem('token')
//   }
// } else {
//   if (!sessionStorage.getItem('token')) {
//     if (window.location.href.split('?')[1]) {
//       const query = window.location.href.split('?')[1]
//       const vars = query.split('&')
//       for (let i = 0; i < vars.length; i++) {
//         const pair = vars[i].split('=')
//         if (pair[0] === 'token') {
//           console.log(pair, 999)
//           // token = pair[1].split('#')[0]
//           sessionStorage.setItem('token', pair[1].split('#')[0])
//         }
//       }
//     }
//   } else {
//     // token = sessionStorage.getItem('token')
//   }
//   console.log(999)
// }
axios.interceptors.request.use(
  config => {
    const token = sessionStorage.getItem('token')
    // if (
    // config.method === 'post'
    // &&config.headers['Content-Type'] !== 'application/json'
    // ) {
    //   config.headers.common['Access-Token'] = token
    // config.data = qs.stringify(config.data);  表单提交json转字符串
    // }
    // if (token && config.method !== 'get') {
    // 统一header传送tonken
    if (token) {
      config.headers.common['Access-Token'] = token
    }
    // }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)
axios.interceptors.response.use(
  (response) => {
    // 报错统一处理
    if (response.data.code) {
      if (response.data.code !== 200) {
        Toast(response.data.message)
      }
    }
    return response.data
  },
  (error) => {
    if (error.response.status) {
      if (error.response.status === 404 || error.response.status === 400 || error.response.status === 500) {
        Toast(error.response.data.error)
      }
      if (error.response.status === 401) {
        Toast('账号已失效；为了安全请重新登录')
        sessionStorage.removeItem('token')
        window.location.href = '#/login'
        return new Promise(() => {})
      }
    }
    return Promise.reject(error)
  }
)
// axios.interceptors.response.use(
//   (error) => {
//     if (error.response.status) {
//       if (error.response.status === 401) {
//         Toast(error.response.data.error)
//       }
//     }
//     return Promise.reject(error)
//   }
// )
export default axios
