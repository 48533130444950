<template>
  <div>
    <Top v-if="!isFinish"/>
    <NavBar title="选择人" router="/package" v-if="!title"/>
    <div class="navbarbox" v-if="!title"></div>
    <div>
      <div class="home">
        <!-- <p class="fs14">请选择为谁提问</p> -->
        <van-radio-group v-model="radio">
          <div class="fx" v-for='(item,index) in list' :key='index' @click="radio = index+1">
            <div>
              <p class="p2">{{item.name}}</p>
              <p class="c8e">
                <span>{{ item.relation==="lover"?'保密':sexs(item.sex)}}</span>
                <span>{{item.age}}岁</span>
              </p>
            </div>
            <div><van-radio :name="index+1" /></div>
          </div>
        </van-radio-group>
        <van-button class="btn" size="large" @click="going"><span>添加家人</span></van-button
        >
      </div>
      <van-button round type="primary" size="large" class="botton" @click='chooseMan'
      >完成</van-button
      >
    </div>
  </div>
</template>

<script>
import NavBar from '../components/navBar'
import Top from '../components/top'
import { getSrvMemberUnChange, updateSrvMember } from '../axios/consultation/home'
export default {
  name: 'relation',
  components: {
    NavBar,
    Top
  },
  data () {
    return {
      isFinish: false,
      radio: 1,
      list: [],
      title: window.navigator.userAgent.toLowerCase().match(/MicroMessenger/i)
    }
  },
  created () {
    if (sessionStorage.getItem('isFinish')) {
      this.isFinish = sessionStorage.getItem('isFinish')
    }
    this.getSrvMemberUnChange()
  },
  methods: {
    getSrvMemberUnChange () {
      getSrvMemberUnChange(sessionStorage.getItem('pdcid')).then((res) => {
        console.log(res)
        this.list = res.data
      })
    },
    sexs (val) {
      switch (val) {
        case 1:
          return '男'
        case 0:
          return '女'
      }
    },
    chooseMan () {
      const toast = this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0,
        overlay: true
      })
      const arr = this.list[this.radio - 1]
      const data = {
        age: arr.age,
        name: arr.name,
        phone: arr.phone,
        relation: arr.relation,
        sex: arr.sex,
        gid: arr.gid,
        pdcid: sessionStorage.getItem('pdcid')
      }
      updateSrvMember(data).then((res) => {
        if (res.code === 200) {
          toast.clear()
          this.$router.push('/package')
        }
        console.log(res)
      })
      console.log(1)
    },
    going () {
      this.$router.push('/inform')
    }
  },

  //   created () {
  //     // this.getPatientsByPid()
  //   },
  //   mounted () {
  //     window.onClickLeft = this.onClickLeft
  //   },
  //   methods: {
  //     onClickLeft () {
  //       this.$router.push('/fast?doctorst=onClickReft')
  //     },
  //     show () {
  //       this.bright = false
  //       console.log(1)
  //     },
  //     onSubmit (values) {
  //       console.log(values)
  //       // console.log('submit', values)
  //       // const data = {
  //       //   age: this.age,
  //       //   name: this.name,
  //       //   parentid: sessionStorage.getItem('ids'),
  //       //   sex: this.sex,
  //       //   allergy: this.message,
  //       //   height: this.height,
  //       //   mdchistory: this.history,
  //       //   weight: this.weight
  //       // }
  //       // addPatient(data).then((res) => {
  //       //   if (res.data.code === 200) {
  //       //     this.list = []
  //       //     this.getPatientsByPid()
  //       //     this.bright = true
  //       //   }
  //       // })
  //     },
  //     // getPatientsByPid () {
  //     //   const data = {
  //     //     parentid: sessionStorage.getItem('ids')
  //     //   }
  //     //   getPatientsByPid(data).then((res) => {
  //     //     if (res.data.code === 200) {
  //     //       this.list = res.data.data.list
  //     //       console.log(this.list)
  //     //     }
  //     //   })
  //     // },
  //     directInquiry () {
  //       console.log(1)
  //       // const data = {
  //       //   access: 12,
  //       //   code: 34,
  //       //   flag: 56,
  //       //   data: {
  //       //     memberid: sessionStorage.getItem('gid'),
  //       //     parentid: sessionStorage.getItem('ids'),
  //       //     doctor_id: sessionStorage.getItem('id'),
  //       //     price: sessionStorage.getItem('price'),
  //       //     trueprice: sessionStorage.getItem('trueprice')
  //       //   }
  //       // }
  //       // directInquiry(data).then((res) => {
  //       //   if (res.data.code === 200) {
  //       //     sessionStorage.setItem('memberid', res.data.data.memberid)
  //       //     sessionStorage.setItem('orderid', res.data.data.gid)
  //       //     sessionStorage.setItem('price', res.data.data.rainprice)
  //       //     sessionStorage.setItem('rainprice', res.data.data.amount)
  //       //     sessionStorage.setItem('parentid', res.data.data.parentid)
  //       //     this.$router.push('/Order?doctorst=onClickLeft')
  //       //   }
  //       // })
  //     },
  //     rapidInquiry () {
  //       console.log(1)
  //       // const data = {
  //       //   access: 12,
  //       //   code: 34,
  //       //   flag: 56,
  //       //   data: {
  //       //     memberid: sessionStorage.getItem('gid'),
  //       //     parentid: sessionStorage.getItem('ids')
  //       //   }
  //       // }
  //       // rapidInquiry(data).then((res) => {
  //       //   if (res.data.code === 200) {
  //       //     sessionStorage.setItem('price', res.data.data.rainprice)
  //       //     sessionStorage.setItem('rainprice', res.data.data.amount)
  //       //     sessionStorage.setItem('memberid', res.data.data.memberid)
  //       //     sessionStorage.setItem('orderid', res.data.data.gid)
  //       //     sessionStorage.setItem('parentid', res.data.data.parentid)
  //       //     this.$router.push('/Order/?doctorst=onClickLeft')
  //       //   }
  //       // })
  //     },
  //     chooseMan () {
  //       console.log(1)
  //       // const data = {
  //       //   age: this.list[this.radio - 1].age,
  //       //   name: this.list[this.radio - 1].name,
  //       //   parentid: sessionStorage.getItem('ids'),
  //       //   sex: this.list[this.radio - 1].sex,
  //       //   user_id: this.list[this.radio - 1].gid
  //       // }
  //       // sessionStorage.setItem('gid', this.list[this.radio - 1].gid)
  //       // chooseMan(data).then((res) => {
  //       //   if (res.data.code === 200) {
  //       //     if (sessionStorage.getItem('id')) {
  //       //       this.directInquiry()
  //       //     } else {
  //       //       this.rapidInquiry()
  //       //     }
  //       //   }
  //       // })
  //     }
  //   },
  filters: {

  }
}
</script>

<style lang="less" scoped>
.navbarbox {
  height: 7vh;
  width: 100%;
}
.fs14 {
  font-size: 14px;
  color: #8e90a5;
  margin: 2vh 0;
}
.home {
  padding-bottom: 13vh;
  margin: 2vh 2vw;
  .fx {
    margin-bottom: 2vh;
    display: flex;
    background-color: #fff;
    justify-content: space-between;
    align-items: center;
    padding: 3vh 2vw;
    border-radius: 10px;
    p {
      margin: 1vh 0;
    }
    .p2 {
      padding: 0 2vw;
    }
    .c8e {
      color: #8e90a5;
      font-size: 14px;
      span {
        padding: 0 2vw;
      }
    }
  }
  .btn {
    border-radius: 2vh;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 4vw;
    }
  }
}
.botton {
  position: fixed;
  top: 90vh;
}
</style>
