<template>
 <div>
   <Top v-if="!isFinish"/>
   <navBar title="完善信息" router="/package" v-if="!title"/>
   <div class="navbarbox" v-if="!title"></div>
   <van-notice-bar
     color="#EAAF07" background="#FFF5DE"
     text="为了医生更安全的诊疗，请填写真实信息"
   >
     <template #left-icon>
       <img src="../assets/anquan.png">
     </template>
   </van-notice-bar>
   <div>
     <van-cell-group>
       <van-field class="basics" label="基础信息" disabled/>
       <van-field  label="与我的关系" disabled/>
       <div class="relation">
         <van-button v-for="(item,index) in arr" :key="index"  :class="item.relations===ship?'select':'noselect'" @click="ships(item.relations,item.sex)" >{{item.relation}}</van-button>
<!--         <van-button color="#F0F0F0" >父亲</van-button>-->
       </div>
       <van-field v-model.trim="name" label="姓名" placeholder="输入真实姓名，用于问诊购药" />
       <van-field v-model.trim="age" label="年龄" type="digit" placeholder="输入真实年月日，用于问诊购药" />
       <van-field v-model.trim="tel" label="手机号" type="digit" placeholder="请输入手机号" />
     </van-cell-group>
       <van-button class="foot" round color="#06C170" @click="preserv">保存</van-button>
   </div>
 </div>
</template>

<script>
import navBar from '../components/navBar'
import { updateSrvMember } from '../axios/consultation/home'
import Top from '../components/top'
export default {
  name: 'information',
  components: {
    navBar,
    Top
  },
  data () {
    return {
      isFinish: false,
      name: '',
      age: null,
      tel: null,
      ship: 'father',
      sex: '1',
      arr: [{ relation: '父亲', relations: 'father', sex: '1' },
        { relation: '母亲', relations: 'mather', sex: '0' },
        { relation: '配偶', relations: 'lover', sex: '1' },
        { relation: '儿子', relations: 'son', sex: '1' },
        { relation: '女儿', relations: 'daughter', sex: '0' }],
      title: window.navigator.userAgent.toLowerCase().match(/MicroMessenger/i)
    }
  },
  created () {
    if (sessionStorage.getItem('isFinish')) {
      this.isFinish = sessionStorage.getItem('isFinish')
    }
    // this.relationship()
  },
  methods: {
    ships (val, key) {
      this.ship = val
      this.sex = key
    },
    preserv () {
      const reg = /^1[345789][0-9]{9}$/
      const _phone = this.tel.toString().trim()

      if (!this.name) {
        this.$toast('请输入姓名')
      } else if (!this.age) {
        this.$toast('请输入年龄')
      } else if (!this.tel) {
        this.$toast('请输入手机号')
      } else if (!reg.test(_phone)) {
        this.$toast('请输入正确的手机号码')
      } else {
        if (this.name && this.age && this.tel && this.ship) {
          const toast = this.$toast.loading({
            message: '加载中...',
            forbidClick: true,
            duration: 0,
            overlay: true
          })
          const data = {
            age: this.age,
            relation: this.ship,
            name: this.name,
            phone: this.tel,
            sex: this.sex,
            gid: '',
            pdcid: ''
          }
          updateSrvMember(data).then((res) => {
            if (res.code === 200) {
              toast.clear()
              this.$router.push('/relation')
            }
          })
        }
      }
    }
    // relationship () {
    // relationship().then((res) => {
    //   console.log(res.data)
    //   this.arr = res.data
    //   this.ship = res.data[0].category
    //   console.log(this.ship)
    // })
    // }
  }
}
</script>

<style scoped lang="less">
.navbarbox {
  height: 7vh;
  width: 100%;
}
.van-notice-bar{
  margin-bottom: 2.6vw;
}
//.van-cell::after{
//  border-bottom: 0;
//}
.basics{
  /deep/.van-cell__title{
    color:#28293D;
    font-size: 16px;
    span{
      font-weight: bold;
    }
  }
}
.relation{
  .van-button{
    font-size: 12px;
    width: 22vw;
    height:8vw;
    border-radius: 1.6vw;
    //color: #06C170 !important;
    margin: 1vw;
  }
  .select{
    background-color: #E1F7EE;
    color: #06C170 !important;
  }
  .noselect{
    background-color: #F0F0F0;
    color: #28293D !important;
  }
}
.foot{
  width: 94.6vw;
  height: 11.7vw;
  margin: 6.6vw 2.7vw;
}
/deep/.van-cell__title{
  color:#28293D
}
</style>
