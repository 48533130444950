<template>
  <div>
    <van-nav-bar
      v-if="!title"
      title="健康服务"
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
    >
      <template #right>
        <van-icon name="search" size="18" />
      </template>
    </van-nav-bar>
    <div style="padding: 0 1.6vw">
    <van-cell-group>
      <van-field v-model="tel" type="digit" @blur="tels"  placeholder="请输入手机号" />
      <div class="btn">
        <van-field v-model="verific"  placeholder="请输入验证码" />
        <van-button color="#f7f7f7" @click="times" :disabled="codeStates">{{ getCodes }}</van-button>
      </div>
    </van-cell-group>
    <van-button @click="logos" class="btn1" color="linear-gradient(to bottom, #9FF8D1,#06C270 )">
      同意协议并登录
    </van-button>
      <van-checkbox class="fs14 bgf" icon-size="14px" v-model="radio"><span>我已阅读并同意</span><span class="colr" @click="goto(1)">《医互葆用户服务协议》</span><span class="colr" @click="goto()">《隐私权协议》</span></van-checkbox>
    </div>
  </div>
</template>

<script>
import { getSearchValue } from '../utils/utils'
import { login, openid, sendCcode, user, logines } from '../axios/consultation/home'
// import {getSearchValue} from '../../utils/utils';
// import navBar from '../components/navBar'
export default {
  name: 'logo',
  // components: {
  //   navBar
  // }
  data () {
    return {
      radio: true,
      verific: null,
      tel: null,
      getCodes: '获取验证码',
      codeStates: false,
      title: window.navigator.userAgent.toLowerCase().match(/MicroMessenger/i)
    }
  },
  created () {
    sessionStorage.removeItem('num')
    const obj = getSearchValue()
    console.log(obj)
    if (obj) {
      this.isFinish = obj.isFinish
      // sessionStorage.isFinish=obj.isFinish
      // sessionStorage.memberid=obj.memberid
      // sessionStorage.token=obj.token
      // sessionStorage.num=obj.num
      for (const key in obj) {
        sessionStorage[key] = obj[key]
        // console.log(obj[key])
      }
    }
    if (window.navigator.userAgent.toLowerCase().match(/MicroMessenger/i)) {
      if (!sessionStorage.getItem('openid')) {
        if (window.location.href.split('?')[1]) {
          const query = window.location.href.split('?')[1]
          const vars = query.split('&')
          for (let i = 0; i < vars.length; i++) {
            const pair = vars[i].split('=')
            if (pair[0] === 'code') {
              console.log(pair, 999)
              sessionStorage.setItem('code', pair[1])
              this.openid()
            }
          }
        }
      }
      const url = 'http://join.xlkyy.com.cn/pd'
      // m=sessionStorage.getItem('p')
      // console.log(m)
      if (!sessionStorage.getItem('code')) {
        // console.log(window.location.href.split('?')[1])
        if (window.location.href.split('?')[1]) {
          sessionStorage.setItem('index', 1)
        }
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx518ad71b8c21c64b&redirect_uri=${url}&response_type=code&scope=snsapi_userinfo&state=STATE&connect_redirect=1#wechat_redirect`
      }
    }
    // sessionStorage('p', sessionStorage.key(inde:p))
  },
  methods: {

    // 获取openid
    openid () {
      openid(sessionStorage.getItem('code')).then((res) => {
        const data = {
          avatarurl: res.data.userinfo.headimgurl,
          city: res.data.userinfo.city,
          // "code": res.data.userinfo.,
          country: res.data.userinfo.country,
          gender: res.data.userinfo.sex,
          nickname: res.data.userinfo.nickname,
          openid: res.data.userinfo.openid,
          // phone: '13555558888',
          province: res.data.userinfo.province,
          unionid: res.data.userinfo.unionid
        }
        sessionStorage.setItem('openid', res.data.userinfo.openid)
        sessionStorage.setItem('data', JSON.stringify(data))
        this.logines()
      })
    },
    // 微信登录
    logines () {
      logines(sessionStorage.getItem('openid')).then((res) => {
        if (res.code === 200) {
          sessionStorage.setItem('token', res.data.nowResult.access)
          sessionStorage.setItem('memberId', res.data.nowResult.subject.uid)
          console.log(res)
          console.log(sessionStorage.getItem('token'), 'login')
          console.log(sessionStorage.getItem('index'))
          // const about = sessionStorage.getItem('about')
          // console.log(about)
          if (sessionStorage.getItem('about')) {
            // if (about === 'about') {
            this.$router.push('/about')
          } else {
            this.$router.push('/')
          }
        }
      })
    },
    user () {
      const data = JSON.parse(sessionStorage.getItem('data'))
      data.phone = sessionStorage.getItem('phone')
      user(data).then((res) => {
        if (res.code === 200) {
          sessionStorage.setItem('token', res.data.nowResult.access)
          sessionStorage.setItem('memberId', res.data.nowResult.subject.uid)
          sessionStorage.getItem('index')
          // const about = sessionStorage.getItem('about')
          // console.log(about)
          if (sessionStorage.getItem('about')) {
          // if (about === 'about') {
            this.$router.push('/about')
          } else {
            this.$router.push('/')
          }
        }
      })
    },
    tels () {
      const reg = /^1[345789][0-9]{9}$/
      const _phone = this.tel.toString().trim()
      if (!reg.test(_phone)) {
        this.$toast('请输入正确的手机号码')
      }
    },
    onClickLeft () {
      console.log(1)
    },
    onClickRight () {
      console.log(2)
    },
    logos () {
      if (this.tel) {
        if (this.verific) {
          if (this.radio) {
            const val = {
              content: this.verific,
              phone: this.tel
            }
            login(val).then((res) => {
              if (res.code === 200) {
                sessionStorage.setItem('phone', this.tel)
                sessionStorage.setItem('token', res.data.nowResult.access)
                sessionStorage.setItem('memberId', res.data.nowResult.subject.uid)
                if (!this.title) {
                  sessionStorage.getItem('index')
                  const about = sessionStorage.getItem('about')
                  // if (sessionStorage.getItem('about')){
                  if (about === 'about') {
                    this.$router.push('/about')
                  } else {
                    this.$router.push('/')
                  }
                } else {
                  this.user()
                }
                // if (sessionStorage.getItem('num')) {
                //   this.$router.push('/')
                // } else {
                // }
              }
            })
          } else {
            this.$toast('请勾选')
          }
        } else {
          this.$toast('请输入验证码')
        }
      } else {
        this.$toast('请输入手机号码')
      }
    },
    // 定时器
    times () {
      if (this.tel) {
        const reg = /^1[345789][0-9]{9}$/
        const _phone = this.tel.toString().trim()
        if (!reg.test(_phone)) {
          this.$toast('请输入正确的手机号码')
        } else {
          let counts = 60
          const that = this
          this.codeStates = true
          const a = setInterval(() => {
            if (counts === 0) {
              that.getCodes = '获取验证码'
              counts = 60
              that.codeStates = false
              clearInterval(a)
              return false
            } else {
              that.getCodes = '（' + counts + '）重新发送'
              counts--
            }
          }, 1000)
          sendCcode(Number(this.tel)).then((res) => {
            console.log(res)
          })
        }
      } else {
        this.$toast('请输入手机号码')
      }
    },
    goto (val) {
      if (val) {
        sessionStorage.setItem('num', val)
      }
      this.$router.push('/a')
    }
  }
}
</script>

<style scoped lang="less">
/deep/ .van-checkbox__icon{
  margin-right: 2vw;
  padding: 1vw;
}
.van-field{
  background-color: #f7f7f7 !important;
}
/deep/.van-hairline--bottom::after{
  border-bottom-width:0
}
/deep/.van-icon-arrow-left{
  color: #fff !important;
}
/deep/.van-icon-search{
  color: #fff !important;
}
/deep/.van-nav-bar__title{
  color: #fff;
}
.fs14{
  font-size: 12px;
}
.van-nav-bar{
  background-color: #06C270;
}
.navbarbox {
  height: 7vh;
  width: 100%;
}
.van-cell-group{
  margin-bottom: 10vw;
  .btn{
    display: flex;
    /deep/.van-button--disabled{
     opacity: 1;}
    .van-button{
      border: 0;
      width: 60vw;
      color: #06C270 !important;
    }
  }
}
.btn1{
  border-radius: 6.6vw;
  width: 100%;
  height:13.3vw;
  margin-bottom: 5vw;
}
.van-checkbox{
  // display: flex;
  /deep/ .van-checkbox__label{
    display: flex;
    flex-wrap: wrap;
  }
  .colr{
    color: #06C270;
  }
}
</style>
