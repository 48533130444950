<template>
<div style="position:relative;">
<!--  <Top v-if="!isFinish"/>-->
  <navBar :title="arrs.title" router="/about" v-if="!title"/>
  <div class="navbarbox"></div>
  <div class="pack" style="">
    <div class="homes">
      <div class="zhong">服务中</div>
      <div class="main">
        <div>
          <p class="ple">{{arrs.title}}</p>
          <p>有效期至{{ timestampToTime(arrs.losetime) }}</p>
        </div>
        <van-button round type="info" color="linear-gradient(to right, #E4F8F1,#ACE9CE)" @click="goto()">立即续费</van-button>
      </div>
    </div>
    <div class="home">
      <van-cell-group inset>
        <van-cell class="fist" title="权益与服务" is-link value="权益说明" @click="to(0)"/>
        <van-cell is-link v-for="(item,index) in arr" :key="index" @click="to(index)">
          <template #title>
            <div class="main-left">
              <img :src="item.pdcurls[0].url">
              <div>
                <p class="c28">{{ item.title }}</p>
                <p class="c8">{{ item.srvinfos }}</p>
              </div>
            </div>
          </template>
        </van-cell>
      </van-cell-group>
      <div class="family">
        <div class="familys">
          <p>我的家人<span>如何分享家人权益</span><van-icon name="question-o" /></p>
          <div class="adds">
            <div class="add">
              <img src="../assets/my.png">
              <div><span>我</span><span class="fs12">本人</span></div>
            </div>
            <div class="add" v-for="(item,index) in list" :key="index">
              <img :src="item.img">
              <div><span>{{item.name.length>=4?item.name.substring(0,3)+'...':item.name}}</span><span class="fs12">{{ item.relation |famil }}</span></div>
            </div>
            <div class="addes" @click="going">
              <van-icon name="plus" />
              <span>添加</span>
            </div>
          </div>
        </div>
      </div>
      <van-cell-group inset class="img">
        <van-cell class="fist" title="其他服务" is-link value="更多产品介绍" @click="goto()" />
<!--        <img src="https://img0.baidu.com/it/u=2866633669,1185368921&fm=26&fmt=auto&gp=0.jpg">-->
        <div class="main" @click="goto()" :style='{"background-image":"url("+arrs.pdcinfos[0].url+")"}' >
          <div>
            <p class="ple">健康无忧服务版</p>
            <p>您专属的健康管家</p>
          </div>
          <van-button round type="info" color="#FF7D4E" >立即购买</van-button>
        </div>
      </van-cell-group>
    </div>
  </div>
</div>
</template>

<script>
import navBar from '../components/navBar'
// import Top from '../components/top'
import { getSrvMemberChanged, getSrvSnp } from '../axios/consultation/home'
export default {
  name: 'package',
  components: {
    navBar
    // Top
  },
  data () {
    return {
      isFinish: false,
      arr: 5,
      list: [],
      arrs: [],
      title: window.navigator.userAgent.toLowerCase().match(/MicroMessenger/i)
    }
  },
  created () {
    if (sessionStorage.getItem('isFinish')) {
      this.isFinish = sessionStorage.getItem('isFinish')
    }
    this.getSrvSnp()
    this.family()
  },
  methods: {
    // 查询家人
    family () {
      getSrvMemberChanged(sessionStorage.getItem('pdcid')).then((res) => {
        this.list = res.data
        console.log(res.data)
      })
    },
    going () {
      this.$router.push('/relation')
    },
    getSrvSnp () {
      const data = {
        orderid: sessionStorage.getItem('orderid'),
        pdcid: sessionStorage.getItem('pdcid'),
        priceid: sessionStorage.getItem('priceids')
      }
      getSrvSnp(data).then((res) => {
        this.arrs = res.data
        this.arr = res.data.srvInfos
        sessionStorage.setItem('arr', JSON.stringify(this.arr))
        console.log(res.data)
      })
    },
    goto () {
      this.$router.push('/index')
    },
    to (val) {
      sessionStorage.setItem('massg', val)
      this.$router.push('/interests')
    },
    //  时间戳
    timestampToTime (cjsj) {
      var date = new Date(cjsj) // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + '-'
      var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
      var D = date.getDate() + ' '
      // var h = date.getHours() + ':'
      // var m = date.getMinutes()
      // var s = date.getSeconds()
      return Y + M + D
    }
  },
  filters: {
    famil (val) {
      switch (val) {
        case 'father':
          return '父亲'
        case 'mather':
          return '母亲'
        case 'lover':
          return '配偶'
        case 'son':
          return '儿子'
        case 'daughter':
          return '女儿'
      }
    }
  }
}
</script>

<style scoped lang="less">
.navbarbox {
  width: 100%;
  height: 26.6vw;
  border-radius: 0 0 5vw 5vw;
  background: linear-gradient(-73deg, #F5E4CE 0%, #DEBC9A 100%);
}
/deep/.van-nav-bar{
  background: linear-gradient(-73deg, #F5E4CE 0%, #DEBC9A 100%);
  //background-color: transparent;
}
/deep/.van-cell__title{
  span{
    font-weight: 700;
    font-size: 16px;
  }
}
.pack{
  position: absolute;
  top: 18vw;
  left: 2.6vw;
  width: 94.8vw;
  .homes{
    height: 30.6vw;
    background-image: url("../assets/pcc.png");
    background-repeat: no-repeat ;
    background-size: 94.6vw 30.6vw ;
    border-radius: 2.9vw;
    margin-bottom: 4vw;
    .zhong{
      box-sizing: border-box;
      font-size: 12px;
      padding:1vw 3vw 1.3vw;
      width: 17.6vw;
      color: #fff;
      height: 5.3vw;
      background-color: #F6A708;
      border-radius: 2.9vw 0 2.9vw 0;
    }
    .main{
      display: flex;
      font-size: 14px;
      margin: 0 6vw;
      margin-top: 5vw;
      justify-content: space-between;
      color: #fff;
      .ple{
        padding-bottom: 2vw;
        font-size: 16px;
      }
    .van-button{
      width: 27.8vw;
      height: 8.4vw;
      color: #00C474 !important;
    }
    }
  }
  .home{
    .van-cell-group{
      margin: 0;
      margin-bottom: 3.7vw;
      //border-radius: 2.9vw;
    }
    .fist{
      .van-cell__title{
        font-size: 15px;
      }
      .van-cell__value{
        font-size: 12px;
        color: #000;
        flex: 3;
      }
    }
    .van-cell{
      align-items: center;
    }
    .van-cell__title{
      flex: 6;
      .main-left{
        display: flex;
      }
      .c28{
        font-size: 15px;
        color: #28293D;
      }
      .c8{
        color: #8E90A5;
        overflow: hidden;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        white-space:nowrap;
        width:60vw;
        display:block;
      }
      img{
        margin-right: 4vw;
        width: 11.7vw;
        height: 11.7vw;
        border-radius: 50%;
      }
    }
    .family{
      height: 30.6vw;
      margin-bottom: 4vw;
      background-color: #fff;
      border-radius: 2.9vw;
      .familys{
        padding: 4.8vw 2.9vw;
        .adds::-webkit-scrollbar {
          display: none;
        }
        .adds{
          margin-top: 3vw;
          display: flex;
          height: 18.3vw;
          //justify-content: center;
          justify-content: left;
          overflow-x: scroll;
          flex-shrink: 0;
          .add{
            flex-shrink: 0;
            //display: flex;
            //flex-direction: column;
            //align-items: center;
            //justify-content: center;
            //padding: 2.6vw;
            padding: 0 2vw;
            border-radius: 1.6vw;
            background-color: #F1F2F4;
            //width: 25vw;
            height: 13.3vw;
            margin-right: 2.9vw;
            display: flex;
            align-items: center;
            img{
              width: 9vw;
              height: 9vw;
              border-radius: 50%;
              margin-right: 2.6vw;
              margin-left: 2.6vw;
            }
            div{
              display: flex;
              font-size: 14px;
              flex-direction: column;
              .fs12{
                color: #8E90A5;
                font-size: 12px;
              }
            }
          }
          .addes{
            border-radius: 1.6vw;
            background-color: #F1F2F4;
            padding: 0 5vw;
            //width: 13.3vw;
            height: 13.3vw;
            margin-right: 2.9vw;
            display: flex;
            //align-items: center;
            text-align: center;
            justify-content: center;
            flex-direction: column;
            font-size: 12px;
            color: #8E90A5;
            .van-icon{
              margin-bottom: 2vw;
            }
            span{
              display: inline-block;
              width: 10vw;
            }
          }
        }
        p{
          display: flex;
          align-items: center;
          span{
            display: inline-block;
            margin: 0 0.8vw;
            font-size: 14px;
            color: #8E90A5;
          }
        }
      }
    }
    .img{
      .van-cell::after{
        border: 0;
      }
      img{
        margin: 2vw 2.6vw;
        width: 89.3vw;
        height: 30.6vw;
      }
      .main{
        height: 30.6vw;
        background-image: url("https://img0.baidu.com/it/u=2866633669,1185368921&fm=26&fmt=auto&gp=0.jpg");
        background-repeat: no-repeat ;
        background-size: 89.3vw 30.6vw ;
        margin: 0 2.6vw;
        border-radius: 2.9vw;
        margin-bottom: 4vw;
        font-size: 14px;
        //color: #fff;
        p{
          margin-left: 5.3vw;
        }
        .ple{
          font-weight: 700;
          font-size: 16px;
          padding-top: 6.4vw;
          padding-bottom: 2.2vw;
          font-size: 16px;
        }
        .van-button{
          margin-top: 3.2vw;
          margin-left: 5.3vw;
          width: 24.8vw;
          height: 8.4vw;
        }
      }
    }
  }
}
</style>
