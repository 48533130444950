<template>
  <div class="navBar">
    <van-nav-bar
      :title="title"
      :left-text="left"
      :right-text="right"
      left-arrow
      @click-left="onClickLeft"
    />
  </div>
</template>

<script>
export default {
  name: 'navBar',
  props: ['title', 'router', 'left', 'right'],
  methods: {
    onClickLeft () {
      this.$router.push(this.router)
    }
  }
}
</script>

<style lang="less" scoped>
.navBar {
  height: 1.3rem;
  width: 100%;
  position: fixed;
  z-index: 5;
}
/deep/.van-nav-bar__content{
  height: 7vh;
}
/deep/.van-hairline--bottom::after{
  border-bottom-width:0
}
/deep/.van-icon-arrow-left{
  color: #000 !important;
}
/deep/.van-icon-search{
  color: #000 !important;
}
/deep/.van-nav-bar__title{
  color: #555770;
}
</style>
