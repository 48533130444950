import axios from '../index'
const config = {
  access: 123,
  code: 456,
  flag: 789
}
export function order (data) {
  // 支付
  return axios({
    url: '/api/m/member/product/order',
    method: 'post',
    data: data
  })
}
export function login (data) {
  // 手机验证码登录,未注册情况,会直接注册登录
  return axios({
    url: '/api/m/pass/member/login/code',
    method: 'post',
    data: { ...config, data }
  })
}
export function sendCcode (data) {
  // 发送手机登录验证码
  return axios({
    url: '/api/m/pass/member/login/send/code',
    method: 'post',
    data: { ...config, data }
  })
}
export function pdcHavePrices (data) {
  // 列表
  return axios({
    url: '/api/o/pass/pdcopens/pdcHavePrices',
    method: 'post',
    data
  })
}
export function getPriceByPdc (data) {
  // 根据产品获取价格详情以及权益集合（懒加载时使用）
  return axios({
    url: '/api/o/pass/pdcprice/getPriceByPdc',
    method: 'post',
    data
  })
}
export function getPdcSnpIcons (data) {
  // 查询已购买产品图片
  return axios({
    url: '/api/o/pass/pdcsnp/getPdcSnpIcons?priceid=' + data,
    method: 'post'
  })
}
export function getSrvMemberUnChange (data) {
  // 根据主账号获取未享受权益家人列表
  return axios({
    url: '/api/o/pdcsrvmember/getSrvMemberUnChange?pdcid=' + data,
    method: 'post'
  })
}
export function updateSrvMember (data) {
  // 编辑或添加享受权益家人
  return axios({
    url: '/api/o/pdcsrvmember/updateSrvMember',
    method: 'post',
    data
  })
}
export function getSrvMemberChanged (data) {
  // 根据主账号获取已享受权益家人列表
  return axios({
    url: '/api/o/pdcsrvmember/getSrvMemberChanged?pdcid=' + data,
    method: 'post'
  })
}
export function getPdcSnps (data) {
  // 查询已购买产品列表
  return axios({
    url: '/api/o/pdcsnp/getPdcSnps',
    method: 'post',
    data
  })
}
export function getSrvSnp (data) {
  // 查询已购买产品下权益列表
  return axios({
    url: '/api/o/pdcsnp/getSrvSnp',
    method: 'post',
    data
  })
}
export function getSrvInfoSnps (data) {
  // 查询已购买产品下权益文档(单个)
  return axios({
    url: '/api/o/pdcsnp/getSrvInfoSnps?priceid=' + data,
    method: 'post'
  })
}
export function getMemberById (data) {
  // 查询邀请码
  return axios({
    url: 'api/m/pass/find/member/getMemberById',
    method: 'post',
    data
  })
}

export function openid (data) {
  // 获取openid
  return axios({
    url: '/api/m/login/member/wx/access/token',
    method: 'post',
    data: { ...config, data }
  })
}
export function user (data) {
  // 微信绑定用户
  return axios({
    url: '/api/m/pass/login/member/wx/bind/user',
    method: 'post',
    data
  })
}
export function logines (data) {
  // 微信绑定用户
  return axios({
    url: '/api/m/pass/login/member/wx/login?openid=' + data,
    method: 'get'
  })
}
export function ticket (data) {
  // 分享
  return axios({
    url: '/api/m/login/member/wx/js/ticket',
    method: 'post',
    data: { ...config, data }
  })
}
export const reduceSrv = (data) => {
  return axios({
    url: '/api/o/pdcsnp/reduceSrv',
    method: 'post',
    data
  })
}
