//截取地址栏参数转换为对象格式
export const getSearchValue = () => {
    if (window.location.href.split('?')[1]) {
        let arr = window.location.href.split('?')[1].replace(/=/g, ':').split('&')
        let obj = {}
        arr.map(v => obj[v.split(':')[0]] = v.split(':')[1])
        return obj
    }
    else {
        return false
    }
}

