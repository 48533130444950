<template>
  <div class="about">
    <Top v-if="!isFinish"/>
    <van-nav-bar
      v-if="!title"
      title="我的服务"
      left-arrow
      @click-left="onClickLeft"
    >
    </van-nav-bar>
<!--    <navBar title="我的服务包" router="/index"/>-->
<!--    <div class="navbarbox"></div>-->
    <van-empty v-if="show" description="您还没有购买过服务包哦" />
    <!-- <div ref="scroll"> -->

        <van-list
  v-model="loading"
  :finished="finished"
  finished-text="没有更多了"
  @load="onLoad"
>
    <div class="bao"  :style="{backgroundImage:'url(' + item.pdcInfoUrlsResponses[0].url+ ')'}" v-for="(item,index) in list" :key="index" @click="time(item.losetime)==='服务中'?go(item.orderid,item.pdcid,item.priceid):goto()">
        <div class="zhong" :class="time(item.losetime)==='服务中'?'':'color2'">{{time(item.losetime)}}</div>
        <div class="main">
          <div>
            <p class="ple">{{ item.title }}</p>
            <p>有效期至{{ timestampToTime(item.losetime) }}</p>
          </div>
        </div>
    </div>
</van-list>
      <!-- </div> -->
  </div>
</template>
<script>
// import navBar from '../components/navBar'
import { getPdcSnps } from '../axios/consultation/home'
import Top from '../components/top'
export default {
  // components: {
  //   navBar
  // },
  components: {
    Top
  },
  data () {
    return {
      isFinish: false,
      list: [],
      limit: 5,
      offset: 0,
      title: window.navigator.userAgent.toLowerCase().match(/MicroMessenger/i),
      loading: false,
      finished: false,
      show: false
    }
  },
  created () {
    if (!sessionStorage.getItem('token')) {
      this.news('token')
    }
    if (window.location.href.split('?')[1]) {
      const query = window.location.href.split('?')[1]
      const vars = query.split('&')
      for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split('=')
        if (pair[0] === 'isFinish') {
          // console.log(pair, 999)
          sessionStorage.setItem('isFinish', pair[1])
          console.log(pair[1].split('#')[0])
        }
        if (pair[0] === 'memberId') {
          // console.log(pair, 999)
          sessionStorage.setItem('memberId', pair[1])
          console.log(pair[1].split('#')[0])
        }
      }
    }
    if (sessionStorage.getItem('isFinish')) {
      this.isFinish = sessionStorage.getItem('isFinish')
    }
    // console.log(this.list)
    this.getPdcSnps()
  },
  // mounted () {
  //   window.addEventListener('scroll', this.handleScroll)
  //   console.log(1)
  // },
  // beforeDestroy () {
  //   window.removeEventListener('scroll', this.handleScroll)
  //   console.log(2)
  // },
  methods: {
    onLoad () {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      if (this.list.length < 5) {
        this.finished = true
      } else {
        this.offset++
        this.getPdcSnps()
        // 加载状态结束
        this.loading = false
      }
      // 数据全部加载完成
    },
    onClickLeft () {
      if (this.title) {
        this.$router.push('/')
      } else {
        window.Android.onX5CloseClicked()
      }
    },
    news (val) {
      if (window.location.href.split('?')[1]) {
        const query = window.location.href.split('?')[1]
        const vars = query.split('&')
        for (let i = 0; i < vars.length; i++) {
          const pair = vars[i].split('=')
          if (pair[0] === val) {
            // console.log(pair, 999)
            sessionStorage.setItem(val, pair[1].split('#')[0])
          }
        }
      }
    },
    // handleScroll () {
    //   if (document.documentElement.scrollTop + document.documentElement.clientHeight + 500 > document.documentElement.offsetHeight) {
    //     this.offset++
    //     this.getPdcSnps()
    //   }
    // },
    getPdcSnps () {
      const data = {
        limit: this.limit,
        offset: this.offset
      }
      getPdcSnps(data).then((res) => {
        if (res.data.list.length === 0) {
          this.finished = true
        } else {
          this.list = [...this.list, ...res.data.list]
        }
        if (this.list.length === 0) {
          this.show = true
        }
        // console.log(res.data.list)
      })
    },
    go (val, key, news) {
      sessionStorage.setItem('orderid', val)
      sessionStorage.setItem('pdcid', key)
      sessionStorage.setItem('priceids', news)
      this.$router.push('/package')
    },
    goto () {
      this.$router.push('/')
    },
    //  时间戳
    timestampToTime (cjsj) {
      var date = new Date(cjsj) // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + '-'
      var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
      var D = date.getDate() + ' '
      // var h = date.getHours() + ':'
      // var m = date.getMinutes()
      // var s = date.getSeconds()
      return Y + M + D
    },
    time (val) {
      var myDate = new Date()
      if (val > myDate.getTime()) {
        return '服务中'
      } else {
        return '已失效'
      }
    }
  }
}
</script>
<style lang="less" scoped>
.navbarbox {
  height: 7vh;
  width: 100%;
}
.bao{
  margin: 4vw 2.7vw;
  background-repeat: no-repeat ;
  background-size: 94.6vw 30.6vw ;
  border-radius: 2.9vw;
  width:  94.6vw;
  height: 30.6vw;
  .zhong{
    box-sizing: border-box;
    font-size: 12px;
    padding:1vw 3vw 1.3vw;
    width: 17.6vw;
    color: #fff;
    height: 5.3vw;
    background-color: #06C170;
    border-radius: 2.9vw 0 2.9vw 0;
  }
  .color2{
    background-color: #999;
  }
  .main{
    display: flex;
    font-size: 14px;
    margin: 0 6vw;
    margin-top: 5vw;
    justify-content: space-between;
    .ple{
      font-weight: 700;
      padding-bottom: 2vw;
      font-size: 16px;
    }
  }
}
</style>
