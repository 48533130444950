import wx from 'weixin-js-sdk'
import { ticket } from '../axios/consultation/home'
const url = window.location.href.split('?')[0]
const wxApi = {
  wxRegister (callback) {
    ticket(url).then((res) => {
      wx.config({
        debug: false, // 开启调试模式
        appId: 'wx518ad71b8c21c64b', // 必填，公众号的唯一标识
        timestamp: res.data.timestamp, // 必填，生成签名的时间戳
        nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
        signature: res.data.signature, // 必填，签名，见附录1
        jsApiList: [
          // 'onMenuShareWeibo',
          // 'getLocation',
          'updateAppMessageShareData',
          'updateTimelineShareData'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
      })
    })
      .catch((error) => {
        console.log(error)
      })
    // wx.ready((res) => {
    //   wx.checkJsApi({
    //     jsApiList: [
    //       'getLocation'
    //     ],
    //     success: function (res) {
    //       if (res.checkResult.getLocation == false) {
    //         alert('你的微信版本太低，不支持微信JS接口，请升级到最新的微信版本！')
    //       }
    //     }
    //   })
    //   wx.getLocation({
    //     success: function (res) {
    //       // 调用百度api 将坐标转换为地理位置
    //       // 传经度纬度，百度地图创建点
    //       // @ts-ignore
    //       const pointAdd = new BMap.Point(res.body.result[0].x, res.body.result[0].y)
    //       // 地址解析器实例
    //       // @ts-ignore
    //       const gc = new BMap.Geocoder()
    //       // 调用百度api 将坐标转换为地理位置
    //       gc.getLocation(pointAdd, function (rs) {
    //         window.sessionStorage.setItem('city', rs.addressComponents.city)
    //       })
    //     },
    //     cancel: function (res) {
    //       alert('用户拒绝授权获取地理位置')
    //     }
    //   })
    //   // 如果需要定制ready回调方法
    //   if (callback) {
    //     callback()
    //   }
    // })
    wx.error(function (res) {
      // console.log(res)
      // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
    })
  },
  ShareTimeline (option) {
    wx.updateTimelineShareData({ // 分享到朋友圈”及“分享到QQ空间
      title: option.title, // 分享标题
      link: option.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
      imgUrl: option.imgUrl, // 分享图标
      success () {
        // 用户成功分享后执行的回调函数
        option.success()
      },
      cancel () {
        // 用户取消分享后执行的回调函数
        option.error()
      }
    })
  },
  ShareAppMessage (option) {
    wx.updateAppMessageShareData({ // 分享给朋友”及“分享到QQ”
      title: option.desc,
      desc: option.desc,
      link: option.link,
      imgUrl: option.imgUrl,
      success: function () {
        option.success()
      },
      cancel: function () {
        option.error()
      }
    })
  },
  ShareAppweibo (option) {
    wx.onMenuShareWeibo({
      title: option.title,
      desc: option.desc,
      link: option.link,
      imgUrl: option.imgUrl,
      success: function () {
        option.success()
      },
      cancel: function () {
        option.error()
      }
    })
  }
}
export default wxApi
