<template>
  <div class="home" :style="'background-color:'+color1">
    <Top v-if="!isFinish"/>
    <van-nav-bar v-if="!title"
      title="健康服务"
      @click-left="onClickLeft"
      @click-right="onClickRight"
    >
      <template #right>
        <img src="../assets/fenx.png" style="width: 16px;">
      </template>
    </van-nav-bar>
    <van-tabs tab-class="tab-class" v-model="active" swipeable  title-active-color="#06C170"
              color="#06C170"
              background="#fff"
              :ellipsis="false"
              :border="false"
              @click="onClick">
      <van-tab v-for="(items,index) in arr"  :title="items.title"   :key="index"  >
<!--        <P v-for="(items,index) in arr" :key="index">{{items.title}}</P>-->
        <img :src="img" style="width: 100vw;">
        <div class="centers">
          <p class="lineTop">尊享服务</p>
          <p class="line" :style="'border-bottom: 2px solid'+ color3"></p>
        </div>
        <div class="service" :style="'background-color:'+color2">
          <p class="title"><span>{{items.title}}将为您节省</span><span :style="'color:'+color3">￥{{ (price/100).toFixed(2)}}</span></p>
          <div class="content">
            <div class="mian" v-for="(item,index) in list" :key="index">
              <div class="main-left">
                <img v-if="item.pdcurls" :src="item.pdcurls[0].url">
                <div style="width: 43vw;">
                  <p style="font-weight: 500" class='guiz'>{{item.title}}</p>
                  <p class="fs14 c8 guiz">{{item.srvinfos}}</p>
                </div>
              </div>
              <div class="main-right fs14"><span>{{ (item.srvprice/100).toFixed(2)}}元/{{item.pdcurls[0].caption}}</span><span>（{{ skg(item.effectcount) }}次）</span></div>
            </div>
          </div>
        </div>
        <div class='dis'>
          <van-checkbox  class="fs14" icon-size="14px" v-model="radio"><span>本次健康管理服务由享乐康提供，已阅读并同意</span><a :href="href" :style="'color:'+color3">《健康管理服务协议》</a></van-checkbox>
          <div class="foot">
            <button class="btn1" :style="'background-image:linear-gradient(to right,'+color1+','+color2+');color:'+color3" disabled><del>￥{{ (oldprice/100).toFixed(2)}}</del><span style="padding:0 0.5vw ">￥{{ (items.pdcprice/100).toFixed(2) }}</span><span class="time" :style="'background-color:'+color3">限时</span></button>
            <van-button color="#393739" class="btn2" @click="goumai">购买服务</van-button>
          </div>
        </div>
        <van-action-sheet v-model="show" title="选择支付方式">
          <div class="content">
            <van-radio-group v-model="radios">
              <van-cell-group>
                <van-cell title="微信"  @click="radios = '2'">
                  <template #icon>
                    <van-icon name="wechat" class='ice' size='20' color='#06C05F'/>
                  </template>
                  <template #right-icon>
                    <van-radio name="2" />
                  </template>
                </van-cell>
                <van-cell title="支付宝"   @click="radios = '1'" v-if="!title">
                  <template #icon>
                    <van-icon name="alipay" class='ice' size='20' color='#1577FE'/>
                  </template>
                  <template #right-icon>
                    <van-radio name="1" />
                  </template>
                </van-cell>
              </van-cell-group>
            </van-radio-group>
            <div class='fot'>
              <div class='actual'>实际支付：<span>￥{{ (items.pdcprice/100).toFixed(2)}}</span></div>
              <van-button type="primary"  round @click='goto' >去支付</van-button>
            </div>
          </div>
        </van-action-sheet>
        <div v-html="html"></div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { order, pdcHavePrices, getPriceByPdc, getPdcSnpIcons, openid, getMemberById } from '../axios/consultation/home'
import wxApi from '../components/weixin'
import Top from '../components/top'

// import { getSearchValue } from '../utils/utils'
// import wx from 'weixin-js-sdk'
// @ is an alias to /src

export default {
  name: 'Home',
  components: {
    Top
  },
  data () {
    return {
      isFinish: false,
      arr: [],
      list: 5,
      active: 0,
      radio: false,
      html: null,
      show: false,
      radios: '2',
      price: '',
      oldprice: '',
      img: '',
      color1: '',
      color2: '',
      color3: '',
      href: '',
      // showShare: false,
      // options: [
      //   [
      //     { name: '微信', icon: 'wechat' },
      //     { name: '朋友圈', icon: 'wechat-moments' },
      //     { name: '微博', icon: 'weibo' },
      //     { name: 'QQ', icon: 'qq' }
      //   ]
      // ],
      ImitateDvo: {
        title: '不要等生病了才想起找医生，  医互葆家庭医生您的健康管家',
        content: '私人健康管家7*24小时贴心服务，家庭医生专家团队为您健康保驾护航。',
        cover: 'http://img.xlkyy.cn/2021-08-194a6051dfa12f47e5bd33ed19c888358d',
        url: 'http://join.xlkyy.com.cn/pd'
      },
      title: window.navigator.userAgent.toLowerCase().match(/MicroMessenger/i)
    }
  },
  created () {
    // this.share()
    this.pdcHavePrices()
    // const obj = getSearchValue()
    // console.log(obj)
    // if (obj) {
    //   this.isFinish = obj.isFinish
    //   // sessionStorage.isFinish=obj.isFinish
    //   // sessionStorage.memberid=obj.memberid
    //   // sessionStorage.token=obj.token
    //   // sessionStorage.num=obj.num
    //   for (const key in obj) {
    //     sessionStorage[key] = obj[key]
    //     // console.log(obj[key])
    //   }
    // }
    if (window.navigator.userAgent.toLowerCase().match(/MicroMessenger/i)) {
      // console.log(1)
      wxApi.wxRegister(this.shares())
      // this.getShareInfo()
      if (!sessionStorage.getItem('code')) {
        this.$router.push('/login')
      }
      // const url = 'http://join.xlkyy.com.cn/pd'
      // if (!sessionStorage.getItem('openid')) {
      //   window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx518ad71b8c21c64b&redirect_uri=${url}&response_type=code&scope=snsapi_userinfo&state=STATE&connect_redirect=1#wechat_redirect`
      // }
    }
    if (!sessionStorage.getItem('token')) {
      const query = window.location.href.split('?')[1]
      const vars = query.split('&')
      for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split('=')
        if (pair[0] === 'token') {
          // console.log(pair, 999)
          sessionStorage.setItem('token', pair[1].split('#')[0])
          console.log(pair[1].split('#')[0])
        }
      }
    }
    if (!sessionStorage.getItem('isFinish')) {
      const query = window.location.href.split('?')[1]
      const vars = query.split('&')
      for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split('=')
        if (pair[0] === 'isFinish') {
          // console.log(pair, 999)
          sessionStorage.setItem('isFinish', pair[1])
          console.log(pair[1].split('#')[0])
        }
        if (pair[0] === 'p') {
          // console.log(pair, 999)
          sessionStorage.setItem('sharememberid', pair[1])
          console.log(pair[1].split('#')[0])
        }
      }
    }
    if (!sessionStorage.getItem('memberId')) {
      this.news('memberId')
    }
    if (sessionStorage.getItem('isFinish')) {
      this.isFinish = sessionStorage.getItem('isFinish')
    }
  },
  mounted () {
    // 将要给原生调用的方法挂载到 window 上面
    window.go = this.go
    // if (sessionStorage.getItem('token')) {
    //   console.log(
    if (sessionStorage.getItem('token')) {
      const DAta = {
        mermberId: sessionStorage.getItem('memberId')
      }
      getMemberById(DAta).then(res => {
        if (res.invitecode) {
          // sessionStorage.setItem('pt', res.invitecode)
          this.ImitateDvo.url = `http://join.xlkyy.com.cn/pd?p=${res.invitecode}&t=${res.buildtime}`
        }
      })
    }
    // this.ImitateDvo.url = `http://join.xlkyy.com.cn/pd?p=${sessionStorage.getItem('pt')}&t=${sessionStorage.getItem('t')}`
    // }
  },
  methods: {
    skg (val) {
      if (val === -1) {
        return '不限'
      } else {
        return val
      }
    },
    news (val) {
      if (window.location.href.split('?')[1]) {
        const query = window.location.href.split('?')[1]
        const vars = query.split('&')
        for (let i = 0; i < vars.length; i++) {
          const pair = vars[i].split('=')
          if (pair[0] === val) {
            // console.log(pair, 999)
            sessionStorage.setItem(val, pair[1])
          }
        }
      }
    },
    shares () {
      // 用于微信JS-SDK回调
      this.wxShareAppMessage()
      this.ShareTimeline()
      this.ShareAppweibo()
    },
    // 微信分享给朋友”及“分享到QQ”
    wxShareAppMessage () {
      const option = {
        title: this.ImitateDvo.title, // 分享标题
        desc: this.ImitateDvo.content,
        imgUrl: this.ImitateDvo.cover,
        link: this.ImitateDvo.url,
        // imgUrl: this.content.figure, // 分享图标,需要绝对路径
        success: () => {
          //
        },
        error: () => {
          //
        }
      }
      wxApi.ShareAppMessage(option)
    },
    // 微信分享到朋友圈”及“分享到QQ空间
    ShareTimeline () {
      const option = {
        title: this.ImitateDvo.title, // 分享标题
        desc: this.ImitateDvo.content,
        link: this.ImitateDvo.url,
        imgUrl: this.ImitateDvo.cover, // 分享图标,需要绝对路径
        success: () => {
          //
        },
        error: () => {
          //
        }
      }
      wxApi.ShareTimeline(option)
    },
    // 微信分享到微博
    ShareAppweibo () {
      const option = {
        title: this.ImitateDvo.title, // 分享标题
        desc: this.ImitateDvo.content,
        link: this.ImitateDvo.url,
        imgUrl: this.ImitateDvo.cover, // 分享图标,需要绝对路径
        success: () => {
          //
        },
        error: () => {
          //
        }
      }
      wxApi.ShareAppweibo(option)
    },
    // 分享和加入按钮
    onClickRight () {
      // 分享组件
      const ua = window.navigator.userAgent.toLowerCase()
      if (!ua.match(/MicroMessenger/i)) { // 安卓
        window.Android.Share(JSON.stringify(this.ImitateDvo))
      } else {
        wxApi.wxRegister(this.shares())
      }
    },
    // 分享
    // getShareInfo () {
    //   // 实现如下：
    //   // 注：timestamp，nonceStr，signature，是通过后端接口返回的，在写下面方法之前可以同axios 方法去进行调用，获取这些参数。
    //   wx.config({
    //     debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
    //     appId: 'wx518ad71b8c21c64b', // 必填，关联公众号的唯一标识
    //     timestamp: '', // 必填，生成签名的时间戳
    //     nonceStr: '', // 必填，生成签名的随机串
    //     signature: '', // 必填，签名
    //     jsApiList: ['updateTimelineShareData', 'updateAppMessageShareData'] // 必填，需要使用的JS接口列表，下面使用什么分享，必须在此进行配置，下面方法才可使用
    //   })
    //   // 必须先调用上面方法，下面的分享功能才可生效。
    //   wx.ready(function () {
    //     wx.updateAppMessageShareData({
    //       title: '加入医互葆，门诊住院有保障', // 分享时的标题
    //       desc: '', // 分享描述
    //       link: '', // 分享时的链接
    //       imgUrl: '', // 分享图标
    //       success: function () {
    //         console.log('分享成功')
    //       },
    //       cancel: function () {
    //         console.log('取消分享')
    //       }
    //     })
    //   })
    // },
    openid () {
      openid(sessionStorage.getItem('code')).then((res) => {
        sessionStorage.setItem('openid', res.data.userinfo.openid)
      })
    },
    onClick (name) {
      this.img = null
      this.href = null
      this.color1 = null
      this.color2 = null
      this.color3 = null
      console.log(1)
      sessionStorage.setItem('title', this.arr[name].title)
      sessionStorage.setItem('priceid', this.arr[name].priceid)
      getPdcSnpIcons(this.arr[name].priceid).then((res) => {
        for (const val of res.data) {
          if (val.pagetype === 1) {
            this.img = val.url
            this.href = val.caption
            console.log(this.href, 999)
          }
          if (val.pagetype === 2) {
            const color = val.url.split('，')
            this.color1 = color[0]
            this.color2 = color[1]
            this.color3 = color[2]
          }
        }
      })
      this.getPriceByPdc(this.arr[name].pdcid, this.arr[name].priceid)
    },
    want () {
      // wx.config({
      //   debug: false, // 开启调试模式
      //   appId: 'wx518ad71b8c21c64b', // 必填，公众号的唯一标识
      //   timestamp: res.data.timestamp, // 必填，生成签名的时间戳
      //   nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
      //   signature: res.data.signature, // 必填，签名，见附录1
      //   jsApiList: [
      //     'onMenuShareWeibo',
      //     'getLocation',
      //     'updateAppMessageShareData',
      //     'updateTimelineShareData'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
      // })
    },
    // onSelect (option) {
    // if (option.name === 'QQ') {
    //   wx.ready(function () { // 需在用户可能点击分享按钮前就先调用
    //     wx.updateAppMessageShareData({
    //       title: '', // 分享标题
    //       desc: '', // 分享描述
    //       link: '', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
    //       imgUrl: '', // 分享图标
    //       success: function () {
    //       // 设置成功
    //       }
    //     })
    //   })
    // }
    //   this.$toast(option.name)
    //   this.showShare = false
    // },
    onClickLeft () {
      window.Android.onX5CloseClicked()
    },
    // 查询
    getPriceByPdc (val, key) {
      const data = {
        pdcid: val,
        position: '',
        priceid: key
      }
      getPriceByPdc(data).then((res) => {
        this.list = []
        this.price = null
        this.oldprice = null
        this.list = res.data.srvInfos
        this.price = res.data.discount
        this.oldprice = res.data.srvamount
        console.log(this.list)
        // console.log(res.data.srvInfos, 999)
      })
    },
    goumai () {
      if (this.radio) {
        this.show = true
      } else {
        this.$toast('请勾选用户授权协议')
      }
    },
    // 查询
    pdcHavePrices () {
      const data = {
        areaname: '',
        caption: '',
        category: '',
        type: 'JIANKANG'
      }
      // const DAta = {
      //   memberId: sessionStorage.getItem('memberId')
      // }
      // if (sessionStorage.getItem('token'))
      // { getMemberById(DAta)
      // }
      // getMemberById(DAta)
      pdcHavePrices(data).then((res) => {
        this.arr = res.data
        sessionStorage.setItem('title', res.data[0].title)
        sessionStorage.setItem('priceid', res.data[0].priceid)
        getPdcSnpIcons(this.arr[0].priceid).then((res) => {
          console.log(res)
          for (const val of res.data) {
            if (val.pagetype === 1) {
              this.img = val.url
              this.href = val.caption
              console.log(this.href)
            }
            if (val.pagetype === 2) {
              const color = val.url.split('，')
              this.color1 = color[0]
              this.color2 = color[1]
              this.color3 = color[2]
            }
          }
        })
        this.getPriceByPdc(this.arr[0].pdcid, this.arr[0].priceid)
      })
    },
    // share () {
    //   if (sessionStorage.getItem('token')) {
    //     const DAta = {
    //       mermberId: sessionStorage.getItem('memberId')
    //     }
    //     getMemberById(DAta).then(res => {
    //       if (res.invitecode) {
    //         sessionStorage.setItem('pt', res.invitecode)
    //       }
    //     })
    //   }
    // },
    goto () {
      const data = {
        paycount: 1,
        priceid: sessionStorage.getItem('priceid'),
        areaname: '',
        describe: sessionStorage.getItem('title'),
        payType: this.radios,
        memberid: sessionStorage.getItem('memberId'),
        sharememberid: sessionStorage.getItem('sharememberid'),
        webNotifyType: 'service'
      }
      if (this.radios === '2') {
        data.openid = sessionStorage.getItem('openid')
        // 微信支付
        const ua = window.navigator.userAgent.toLowerCase()
        console.log(ua, 999)
        console.log(ua.match(/MicroMessenger/i), 999)
        if (ua.match(/MicroMessenger/i)) {
          data.type = 1
          order(data).then((res) => {
            const oderInfo = res.data.oderInfo
            this.wxPay(oderInfo)
          })
        } else {
          data.type = 2
          order(data).then((res) => {
            let oderInfo = res
            oderInfo = JSON.stringify(oderInfo)
            console.log(oderInfo)
            window.Android.payfor(oderInfo)
          })
        }
      } else {
        data.type = 0
        order(data).then((res) => {
          this.html = res.data.form
          this.$nextTick(() => {
            document.forms[0].submit()
          })
        })
      }
      // order(data).then((res) => {
      //   console.log(res)
      //   if (res.data.form) {
      //     this.html = res.data.form
      //     this.$nextTick(() => {
      //       document.forms[0].submit()
      //     })
      //   } else {
      //     let oderInfo = res.data.oderInfo
      //     oderInfo = JSON.stringify(oderInfo)
      //     console.log(oderInfo)
      //     window.Android.payfor(oderInfo)
      //   }
      // })
    },
    // 微信支付
    wxPay (wxData) {
      const that = this
      function onBridgeReady () {
        // eslint-disable-next-line no-undef
        WeixinJSBridge.invoke(
          'getBrandWCPayRequest', wxData,
          res => {
            if (res.err_msg === 'get_brand_wcpay_request:ok') {
              // 使用以上方式判断前端返回,微信团队郑重提示：
              // res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              // @ts-ignore
              that.$toast('支付成功')
              // let orderInfo = {
              //   amount: window.sessionStorage.getItem('sum'),
              //   online: window.sessionStorage.getItem('online')
              // }
              that.$router.push('/about')
              // that.$router.push({path:'/paySuccess',query: orderInfo})
              // that.queryOders = setInterval(() => {that.queryOder()},1000)
            } else if (res.err_msg === 'get_brand_wcpay_request:cancel') {
              that.$toast('支付失败')
              // that.submiting = false
            } else if (res.err_msg === 'get_brand_wcpay_request:fail') {
              that.$toast('支付失败')
              // that.submiting = false
            }
          })
      }
      // @ts-ignore
      if (typeof WeixinJSBridge === 'undefined') {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false)
          // @ts-ignore
        } else if (document.attachEvent) {
          // @ts-ignore
          document.attachEvent('WeixinJSBridgeReady', onBridgeReady)
          // @ts-ignore
          document.attachEvent('onWeixinJSBridgeReady', onBridgeReady)
        }
      } else {
        onBridgeReady()
      }
    },
    go () {
      // this.$router.push('/')
      this.$router.push('/about')
    }
    // 调用安卓微信支付
  }
}
</script>
<style lang="less" scoped>
/deep/.van-checkbox__label{
 display: flex;
 flex-wrap: wrap;
}
/deep/.van-checkbox__icon{
  margin-right: 2vw;
  padding: 1vw;
}
/deep/.van-hairline--bottom::after{
  border-bottom-width:0
}
/deep/.van-icon-arrow-left{
  color: #000 !important;
}
/deep/.van-icon-search{
  color: #000 !important;
}
/deep/.van-nav-bar__title{
  color: #555770;
}
.bgf{
  background-color: #fff;
}
.fs14{
  font-size: 12px;
}
.c8{
  color: #8E90A5;
}
.home{
  //background-color: #FFF8F8;
  .centers{
    //text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 2vw;
    .lineTop{
      padding-bottom: 2vw;
    }
    .line{
      width: 10vw;
      //border-bottom: 2px solid #CF5839;
    }
    //justify-content: center;
  }
}
.service{
  margin: 0 2.6vw;
  margin-bottom: 80px;
  //background-color: #FFE8E4;
  border-radius: 24px;
  padding: 4vw 2.6vw;
  padding-top:0 ;
  .title{
    display: flex;
    justify-content: space-between;
    padding-top: 5.7vw;
    padding-left:4.1vw ;
    padding-bottom:4vw ;
    padding-right: 4vw;
    span{
      font-weight: 700;
    }
  }
  .content{
    background: #FFFFFF;
    border-radius: 24px;
    //overflow-y: scroll;
    .mian{
      padding: 10px 0;
      display: flex;
      justify-content: space-between;
      .main-left{
        display: flex;
        align-items: center;
        img{
          margin-right: 1vw;
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }
        p{
          line-height: 3vh;
        }
      }
      .main-right{
        width: 26vw;
        height: 6vw;
        display: flex;
        align-items: center;
        flex-direction: column;
        border:1px solid #8A8CA4;
        display: flex;
        padding: 2vw;
      }
    }
  }
}
.dis{
 position: fixed;
  bottom: 0vw;
  width: 100%;
}
.van-checkbox{
  // line-height: 9vw;
  background: #fff;
  // position: fixed;
  // bottom: 9vw;
  // width: 100%;
  padding: 2vw;
  // padding-top: 0;
  //a{
    //color: #F77245;
  //}
}
.foot{
  // position: fixed;
  // bottom: 0vw;
  // width: 100%;
  display: flex;
  justify-content: center;
  /deep/.van-button--disabled{
    opacity: 1;
  }
  .btn1{
    flex:1;
    //color: #F77245;
    border:0;
    //background-image: linear-gradient(to right, #FEEEE9,#FDE2D7);
    del{
      font-size: 14px;
    }
    span{
      font-size: 16px;
      //small{
      //  font-size: 15px;
      //}
    }
    .time{
      font-size: 12px;
      display: inline-block;
      padding: 1vw;
      //background-color: #F77245;
      color: #fff;
      width: 10vw;
      border-radius: 2vw 3vw 3vw 0;
    }
  }
  .van-button{
    flex:1
  }
}
.content {
  padding: 16px 2vw 1vh;
  .fot{
    width: 100%;
    margin-top: 2vh;
    padding-bottom: 2vw;
    background-color: #fff;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .actual{
      font-size: 14px;
      span{
        font-size: 16px;
        color:red
      }
    }
    .van-button{
      height: 8vw;
      width: 34.6vw;
    }
  }
}
.guiz{
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}

</style>
