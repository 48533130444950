<template>
      <div v-if="xiazai" class="xiazai">
          <div style="display:flex;align-items:center;">
            <van-icon @click="close" class="pd1" name="cross"/>
              <img class="pd1" src="../assets/applogo.png" alt="">
            <div class="xiazai-textpd1">
            享乐康App消费得医保，互助享报销<br/>
            <span style="font-size:2.8vw">加入医互葆工程，看病买药可报销</span>
            </div>
            <div class="xiazai-btn" @click="goxiazai">立即下载</div>
          </div>
      </div>
</template>

<script>
export default {
  name: 'top',
  data () {
    return {
      xiazai: true
    }
  },
  methods: {
    close () {
      this.xiazai = false
    },
    goxiazai () {
      window.location.href = 'http://www.xlkyy.com.cn/app.html'
    }
  }
}
</script>

<style scoped lang="less">
.xiazai {
  font-size: 3.7vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  background-color: #333;
  padding: 1.3vw 0;
  .xiazai-text{
    height: 10vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between
  }
  .xiazai-btn {
    background-color: #06c270;
    padding: 2vw;
    margin-right: 1.5vw;
    border-radius: 4px;
  }
  img{
    width: 10vw;
    height: 10vw;
    border-radius: 6px;
  }
}
.pd1{
  padding: 0 1vw;
}
</style>
